@prefix:api-post;

.@{prefix}-menu{
    max-width: 256px;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum";
    padding: 0;
    color: #000000d9;
    font-size: var(--size-14px);
    text-align: left;
    background: transparent;
    outline: none;
    // box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    transition: background .3s,width .3s cubic-bezier(.2,0,0,1) 0s;
    color:var(--fn1);
    &>ul li{
    list-style: none;
    // width: 244px;
    height: 38px;
   }
   &>ul li {
       cursor: pointer;
   }
   &-hiddle{
       display: none;
   }
   .api-post-menu-submenu-active {
       background-color: var(--background-4);
   }
   .api-post-men-submenu{
       display: flex;
       justify-content: space-between;
       padding: 0 12px;
       height: 38px;
       line-height: 38px;
       color:var(--fn1);
       cursor: pointer;
       width: 100%;
       img{
           width: 20px;
           height: 20px;
           margin-right: 8px;
       }
       &:hover{
        background-color:  var(--bgr1);
        border-radius: 3px;
       }
   }
   .api-post-men-submenu-active{
        background-color:  var(--bgr1);
   }
   .api-post-menu-item{
       padding: 6px 8px;
       margin-bottom: 4px;
       border-radius: 3px;
       display: flex;
       font-size: var(--size-12px);
       align-items: center;
       & .arrow-right{
            height: 10px;
            width: 10px;
            transform: rotate(180deg);
           fill: var(--fn1);
       }
       img{
           margin-right: 12px;
           width: 20px;
           height: 20px;
           vertical-align: text-bottom;
       }
       &:hover{
           background-color: var(--bgr1);
       }
       
   }
   .api-post-menu-item:last-child{
       margin: 0;
   }
}
.api-post-menuitem-hover{
    position: absolute;
    left: 172px;
    top: 0;
    background-color: var(--bgr5);
    padding: 8px;
    border: 1px solid var(--bd4);
    box-shadow: 0px 4px 10px var(--bgr6);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    .api-post-menu-item{
        min-width: 100px;
        padding: 6px 8px;
        margin-bottom: 4px;
        height: 28px;
        line-height: 28px;
        color:var(--fn1);
        cursor: pointer;
        border-radius: 3px;
        display: flex;
        font-size: var(--size-12px);
        align-items: center;
        white-space: nowrap;
        &:hover{
            background-color: var(--bgr1);
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

}