.api-input-group {
    background: var(--bg1);
    border: 1px solid var(--bd8);
    border-radius: 5px;
    display: inline-block;
    line-height: 0;
    width: 100%;

    // overflow: hidden;
    &:hover {
        border: 1px solid var(--bd4);
    }

    .api-input {
        background: var(--bg1);
        border: 0px;
        font-size: var(--size-14px);
        width: 100%;
        height: 100%;
        caret-color: var(--fn1);
        color: var(--fn3);
        outline: 0px;
        padding: 2px 12px;
        border-radius: 5px;

        &::-webkit-input-placeholder {
            color: var(--fn5);
        }
    }

    .api-input-error {
        background: var(--error);
    }

    .api-input-large {
        font-size: var(--size-24px);
    }

    .api-input-middle {
        font-size: var(--size-14px);
    }

    .api-input-small {
        font-size: var(--size-16px);
    }
}

.api-input-group-focus {
    border: 1px solid var(--bd4) !important;
}

.api-input-group-error {
    background: var(--error);
    border: 1px solid var(--error);

    &:hover {
        border: 1px solid var(--error) !important;
        // box-shadow: 2px 2px 2px var(--error);

    }
}


.api-input-tipWarning,
.api-input-tipError {
    margin-top: 6px;
    display: inline-block;
    color: var(--error);
    font-weight: normal;
    font-size: var(--size-14px);

    &:hover {
        border: 1px solid var(--error);
    }
}

.api-textarea-wrapper {
    width: 100%;
    position: relative;

    .api-textarea {
        background-color: transparent;
        // background: var(--bg1);
        // border: 1px solid var(--bg4);
        color: var(--fn3);
        font-size: var(--size-12px);
        // border-radius: 10px;
        box-sizing: border-box;
        font-family: "PingFang SC";
        width: 100%;
        padding: 2px 4px;
        resize: none;
        overflow-y: hidden;
        caret-color: var(--fn1);
        top: 0;
        left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        /* Opera/IE 8+ */
        &:focus {
            // border-color: var(--bd4);
            border-right-width: 1px !important;
            outline: 0;
        }

        &::-webkit-input-placeholder {
            color: var(--fn5);
        }

    }

    .api-input-clear-icon {
        position: absolute;
        top: 0;
        right: 2px;

        img {
            width: 12px;
            height: 12px;
        }
    }
}