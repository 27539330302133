.api-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 88px;
  min-height: 22px;
  // background: var(--bg1);
  border: 1px solid var(--bd4);
  border-radius: 5px;

  .api-select-selector {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 4px 8px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;

    .api-select-selector-value {
      font-size: var(--size-12px);
      color:var(--fn2);
      min-width: 53px;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      pointer-events: none;
      &.disabled{
        color: var(--fn3);
      }
    }

    .api-select-selector-icon {
      width: 16px;
      height: 16px;
      line-height: 14px;
      pointer-events: none;
      &>svg{
          fill: var(--fn1);
      }
      & .arrow-top{
        transform: rotate(180deg);
      }

    }
  }

  .api-select-selector-open {
    // background: #EEEFF2;
  }
}

.api-select-options {
  background-color: var(--bgr5);
  border: 1px solid var(--bd4);
  box-shadow: 0px 4px 10px var(--bgr6);
  backdrop-filter: blur(20px);
  display: none;
  border-radius: 5px;
  padding: 8px;
  z-index: 1030;
  max-height: 350px;
  overflow-y: auto;
  cursor: pointer;
  .api-select-option {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px;
    color: var(--fn1);
    margin-bottom: 4px;
    &:last-child{
      margin-bottom: 0;
    }
    .api-select-option-value {
      font-size: var(--size-12px);
      min-width: 58px;
      // max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      display: flex;
      align-items: center;
    }
  }

  .api-select-option:hover {
    background: var(--bgr1);
    border-radius: 3px;
  }

  .api-select-option-action {
    background: var(--bg6);
    border-radius: 3px;
    color: #ffffff;
  }

  .api-select-option-action:hover {
    background: var(--bg6);
  }
}

.api-select-options-show {
  display: flex;
  flex-direction: column;
  position: fixed;

  z-index: 1030;
  border: 1px solid var(--bg1);
}