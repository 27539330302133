.api-message-group {
    transition: .3s linear;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: var(--size-14px);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 9999;
    width: 100%;
    pointer-events: none;

    .api-message {
        padding: 8px;
        text-align: center;

        .api-message-content {
            display: inline-block;
            padding: 10px 16px;
            box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
            pointer-events: all;
            border-radius: 10px;
            .api-message-icon {
                top: 3px;
                position: relative;
                margin-right: 8px;
                font-size: var(--size-16px);
                display: inline-block;
            }

            .api-message-text {
                display: inline-block;
            }

            .api-message-close {
                display: inline-block;
                margin-left: 10px;
            }
        }
        .api-message-error{
            background: #FFF5F7;
            border: 1px solid #FF4C4C;
            color: #2B2B2B;
        }
        .api-message-info{
            background: #F1F5FF;
            border: 1px solid #5D88F1;
            color: #2B2B2B;
        }
        .api-message-success{
            background: #F6FFF2;
            border: 1px solid #3CC071;
            color: #2B2B2B;
        }
        .api-message-warning{
            background: #FFFEE8;
            border: 1px solid #DFBB00;
            color: #2B2B2B;
        }
    }

    &:after {
        content: "";
        display: inline-block;
    }
}

.loading-text {
    text-align: left;
    color: #fff;
    font-size: .24rem;
    // font-family: sans-serif;
    line-height: .75rem;
    padding-left: .85rem;
    padding-right: .26rem;
}

.loading-enter,
.loading-leave-active {
    opacity: 0;
}