

.apipost-tips-popup{
  position: fixed;
  background-color: var(--bgr5);
  border: 1px solid var(--bd4);
  box-shadow: 0px 4px 10px var(--bgr6);
  color: var(--fn1);
  backdrop-filter: blur(20px);
  padding: 10px;
  border-radius: 5px;
  z-index:1200 ;

}






