.api-close-btn {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: #FAFAFA;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  svg {
    fill: var(--fn1);
  }

  &:hover {
    background: var(--bg1);
  }
}