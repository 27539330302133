.api_notice {
  width: 390px;
  height: 451px;
  padding: 24px;
  border-radius: 5px;
  background-color: var(--bg7);
  position: relative;
  border: 1px solid var(--bd4);
  box-shadow: 0px 4px 10px var(--bgr6);
  line-height: 1.5;
  .proEmpty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .api_notice_tab {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    .panel {
      // margin-right: 32px;
      width: 101.33px;
      height: 32px;
      padding: 8px;
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-weight: 600;
      font-size: var(--size-14px);
      color: var(--fn1);
      cursor: pointer;
      svg {
        position: absolute;
        right: 12px;
        top: 2px;
      }
      &.active {
        background-color: var(--bgr1);
      }
    }
  }
  .api_notice_con {
    // height: 625px;
    .api_post_empty_container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .proEmpty {
      .api_post_empty_container {
        position: static;
        transform:none;
      }
    }
    ul {
      height: 315px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        display: flex;
        color: var(--fn1);
        padding: 8px 8px 8px 16px;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        justify-content: space-between;
        .api_notice_li_left {
          display: flex;
          width: 100%;
          align-items: center;
          .notice_icon {
            // padding: 10px;
            position: static;
            width: 35px;
            min-width: 35px;
            height: 35px;
            // background-color: #3a86ff;
            margin-right: 12px;
            border-radius: 5px;
            overflow: hidden;
          }
        }
        .notice_message {
          flex: 1;
          margin-bottom: 6px;
          .notice_title {
            font-weight: 600;
            font-size: var(--size-12px);
          }
          .more {
            position: absolute;
            font-size: 12px;
            bottom: 15px;
            right: 25px;
            color: #3a86ff;
          }
          .notice_desc {
            font-weight: normal;
            font-size: var(--size-12px);
            &.click {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          .time {
            font-style: normal;
            font-weight: normal;
            font-size: var(--size-12px);
            white-space: nowrap;
          }
        }
        .notice_time {
          min-width: 50px;
          text-align: right;

          .link {
            font-weight: normal;
            font-size: 12px;
            color: #3a86ff;
          }
        }

        .is_readed {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: var(--bgr1);
        }
      }
      .driver {
        height: 1px;
        margin: 8px 0;
        background-color: var(--bd4);
      }
    }
  }
  .notice_foot {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 57px;
    background-color: var(--bg2);
    border-top: 1px solid var(--bd4);
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .clear_notice_idot {
      margin-right: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }
}
