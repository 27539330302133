@import "./../Them/index.less";

.api_post_empty_container{
    // min-width: 500px;
    div{
        width: 100%;
        text-align: center;
    }
    .api_post_empty_text{
        margin: 8px 0 0 0;
        text-align: center;
        color: var(--fn3);
        margin-top: 22px;
    }
}
.proEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 220px;
    justify-content: space-around;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
}