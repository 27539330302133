.title {
  font-size: var(--size-24px);
  line-height: 33px;
  margin-bottom: 32px;
}
.modal_header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  .title {
    margin: 0;
  }
  button {
    width: 110px;
    height: 40px;
    border-radius: 25px;
    margin-left: 32px;
  }
}
.itemidle {
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin: 8px 0;
  &:hover {
    background-color: var(--background-4);
  }
}
