.layout_main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;

  .login_left {
    width: 600px;
    min-width: 600px;
    height: 100%;
    min-height: 100vh;
    padding: 80px 60px;
    display: flex;
    flex-direction: column; /* 设置为垂直方向的弹性布局 */
    justify-content: space-between;
    background: url(../../assets/img/login_banner.svg)  no-repeat;
    background-size: cover;
    .login_logo {
      height: 46px;
      margin: 0 auto;
      // margin-top: 170px;
      display: flex;
      // align-items: center;
      // justify-content: center;
      .test_version {
        padding: 0 8px;
        height: 20px;
        line-height: 20px;
        background: #fff;
        color: #3A86FF;
        font-size: 12px;
        // text-align: center;
        margin: 0 0 0 10px;
        border-radius: 3px;
      }
    }

    .login_title {
      height: 80px;
      margin: 0 auto;
      margin-top: 40px;
      font-weight: 600;
      font-size: 46px;
      line-height: 70px;
      color: #FA7600;
      // text-align: center;
    }

    .login_desc {
      height: 34px;
      margin: 0 auto;
      margin-top: 70px;
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      // text-align: center;
      color: #535353;
    }
    .line-with-text {
      display: flex;
      flex-direction: row;
      color: #535353;;
      font-size: 16px;
      margin-bottom: 10px;
    }
    
    .line-with-text:before,
    .line-with-text:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid rgba(102, 102, 102, 0.20);
      margin: auto 8px;
    }

  
    .login_brand{
      margin-bottom: 10px;
      padding: 10px 0;
      height: 200px;
    }
    .login_banner {
      width: 333px;
      height: 323.19px;
      margin: 0 auto;
      margin-top: 101px;
    }
  }
  .right_con{
    flex: 1;
    position: relative;
  }
}

/* 样式示例，替换成您想要的样式 */
.carousel-root {
  position: relative;
}

.carousel {
  position: relative;
}

.carousel .control-dots {
  position: absolute;
  bottom: -50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel .control-dots .dot {
  background-color: #f8ae6b; /* 指示器未选中时的颜色 */
  width: 12px;
  height: 12px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: none !important;
}

.carousel .dot.selected {
  background-color: #FA7600; /* 指示器选中时的颜色 */
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: visible !important; 
}

.ant-carousel .slick-dots-bottom {
  bottom: -20px;
}

/* 修改dots的样式 */
.carousel-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #f8ae6b; /* 设置dots的背景颜色 */
  border-radius: 50%; /* 使dots呈现圆形 */
}
.ant-carousel .slick-dots li{
  background-color: #f8ae6b;
}

.ant-carousel .slick-dots li.slick-active button{
  background-color: #FA7600; /* 设置激活状态下的颜色 */
}
