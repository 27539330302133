.open {
  display: block;
}
.hide {
  display: none;
}
.api-post-drop-menu{
  width: 100%;
}
.api-post-dropmenu-container {
  background-color: var(--bgr5);
  border: 1px solid var(--bd4);
  box-shadow: 0px 4px 10px var(--bgr6);
  backdrop-filter: blur(20px);
  position: absolute;
  border-radius: 15px;
  padding: 6px 8px;
  width: auto;
  z-index: 1025;
  // opacity: 0;
  // display: none;
  visibility: hidden;
}
