.api-post-radio-wrapper{
    box-sizing: border-box;
    margin: 0 8px 0 0;
    padding: 0;
    color: #000000d9;
    font-size: var(--size-14px);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;


.api-post-Radio{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: var(--size-14px);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: 0.2em;
    display: inline-block;
    outline: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        visibility: hidden;
    }
    input[type='radio']{
        padding: 0;
        box-sizing: border-box;
        opacity: 0;
    }
    .api-post-radio-inner{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-color: var(--fn2);
        border-style: solid;
        border-width: 1px;
        border-radius: 50%;
        transition: all .3s;
        // margin: 0 10px 0 0 ;
        // box-shadow: 0 0 10px var(--bg6); 
    }
    .api-post-radio-inner-checked{
        // position: relative;
        // border-width: 5px;
        border-color: var(--bg6);
        transition: all 3s cubic-bezier(.78,.14,.15,.86);
        &::after{
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            // width: 10px;
            // height: 10px;
            margin-top: -10px;
            margin-left: -10px;
            background-color: #fff;
            // border-top: 0;
            // border-left: 0;
            border-width: 5px;
            border-style: solid;
            border-radius: 16px;
            padding: 5px;
            border-color: var(--bg6);
            transition: all 3s cubic-bezier(.78,.14,.15,.86);
            content: " ";
        }
    }
}
.api-post-children{
    padding: 0 5px;
}
}
