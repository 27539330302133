// @aaa:5px;
// div{
//     // border: @aaa red solid
// }

// @import '~antd/dist/antd.css';
@apiPost: .api_post;

div,
p,
ul,
li {
  margin: 0;
  padding: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

img {
  width: 100%;
  height: 100%;
}

* {
  outline: none;
}
#root,
body {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
  // background: var(--bg1);
}

input[type="password"]::-ms-reveal {
  display: none;
}

.cur_pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: var(--fn2);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: var(--bg5);
}
.padidng-medium {
  padding: 12px;
}
.margin-lg {
  margin: 0 16px 0 0;
}
.text-left {
  text-align: left;
}
.font-16 {
  font-size: var(--size-16px);
}
.text-ellips {
  display: inline-block !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-ellips2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-min-width {
  min-width: 80px;
  width: 200px;
}
.inline-block {
  display: inline-block;
}
.Notice_tooltip {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  backdrop-filter: blur(0) !important;
  box-shadow: none !important;
}
#captcha {
  width: 100%;
  height: 42px;
  & > div {
    height: 100%;
  }
  div {
    background-image: none;
    // background: var(--bg2);
  }
  .geetest_holder {
    background: transparent;
    .geetest_logo {
      background-image: url(./assets/logo/logo.png);
      background-size: 100% 100%;
      pointer-events: none;
    }
    .geetest_btn_click {
      // border: 1px solid transparent;
      border-radius: 5px;
      &:hover {
        background-image: none;
      }
    }
    .geetest_tip_container {
      background: var(--bg2);
      border-radius: 5px;
      .geetest_tip {
        color: var(--fn1);
      }
    }
    .geetest_content {
      border-radius: 5px;
      border: 1px solid var(--bd8);
      background: transparent;
      background-image: none;
    }
    .geetest_mask {
      background-color: var(--bg2);
    }
    .geetest_box_wrap .geetest_box {
      border: var(--bd4) 1px solid;
      background: transparent;
    }
    .geetest_bind_box {
      background: transparent;
    }
    .geetest_box_btn {
      background: var(--bg2);
      border: 1px solid var(--bd8);
    }
    .geetest_feedback {
      display: none;
    }
    .geetest_box_logo {
      background-image: url(./assets/img/logo.svg);
      background-size: 100% 100%;
      pointer-events: none;
    }
    .geetest_header {
      background-color: var(--bg2);
      .geetest_text_tips {
        color: var(--fn1);
      }
    }
    .geetest_container {
      background-color: var(--bg2);
      // transform: none;
    }
    .geetest_footer {
      padding: 48px 0 0 0;
      background: var(--bg2);
      border-top: 1px solid var(--bd4);
    }
  }

  .geetest_btn {
    padding-bottom: 12%;
    top: -50%;
    width: 20%;
    background-color: #FA7600;
    .geetest_arrow.geetest_arrow_1 {
      background-image: url(https://static.geetest.com/v4/static/v1.4.7/css/sprite@2x.png);
    }
  }
  .geetest_status_bar{
    background-color: #FA7600;
  }
  .geetest_gradient_bar{
    background-color: #FA7600;
  }
}
.api-content .scale-item {
  // overflow: visible !important;
}

.toastui-editor-dark .toastui-editor-contents p {
  word-break: break-all;
}
.geetest_popup_wrap.geetest_rem_auto
  .geetest_box_wrap
  .geetest_box
  .geetest_footer
  .geetest_footer_left
  .geetest_feedback {
  display: none;
}
.geetest_popup_wrap.geetest_rem_auto
  .geetest_box_wrap
  .geetest_box
  .geetest_footer
  .geetest_footer_right
  .geetest_box_logo {
  background-image: url(./assets/img/logo.svg);
  background-size: 100% 100%;
  pointer-events: none;
}
