.APT-header {
  padding: 0 40px 0 30px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  background: #fff;

  .message-count {
    position: absolute;
    top: -10px;
    right: -15px;
    background: #ff4c4c;
    padding: 0px 4px;
    border-radius: 5px;
    color: #fff;
    line-height: 17px;
    font-size: var(--size-12px);
  }

  .header_left {
    min-width: 164px;

    .logo_img {
      width: 126px;
      height: 32px;
    }

    .logo_img_s {
      width: 32px;
      height: 32px;
    }

    .header_left_menu {
      display: flex;
      align-items: center;
      height: 38px;
      width: 200px;

      .shu {
        height: 100%;
        width: 2px;
        background: #f2f2f2;
        margin: 0 6px;
      }

      &-team {
        font-size: 16px;
        cursor: pointer;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .team-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 150px;
          margin: 0 0 4px 0;
          background-image: url("../../assets//img/dropdown.svg");
          background-repeat: no-repeat;
          background-size: 16px 16px;
          background-position: 100% 0;

          .text-ellips2 {
            max-width: 130px;
            min-width: 130px;
          }
        }

        .team-tip {
          font-size: 12px;
          color: #666;
          display: flex;
          align-items: center;
          white-space: nowrap;

          .tip-version {
            width: auto;
            height: 19px;
            line-height: 19px;
            background: #F0F6FE;
            padding: 0 4px;
            border-radius: 3px;
            color: #3A86FF;
            text-align: left;
            margin: 0 0 0 4px;
          }
        }
      }
    }
  }

  .header_right {
    height: 44px;
    display: flex;
    align-items: center;

    img {
      vertical-align: bottom;
    }

    .notice {
      font-size: 14px;
      height: 24px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .download {
      font-size: 14px;
      height: 24px;
      margin-left: 40px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .portrait {
      width: 44px;
      height: 44px;
      cursor: pointer;
      border-radius: 50%;
      // overflow: hidden;
      margin-left: 40px;
      position: relative;
    }
  }
}

.userInfoHeader {
  text-align: center;

  .username {
    font-size: 24px;
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .useremail {
    color: #666666;
  }
}

.header_user_dropmenu {
  background-color: #fff;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  box-shadow: 0px 5px 30px rgba(52, 52, 52, 0.1);
}

.Header_dropmenu {
  .userInfoHeader {
    margin-top: 12px;
  }

  .api-post-menu-item {
    border-radius: 3px;

    &:hover {
      background-color: #F9F9F9;
    }
  }

  li {
    margin: 0 !important;
  }
}

.user_badge {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 18px;
  background: #FFFFFF;
  /* 颜色/线 */
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    li{
      width: 14px;
      height: 14px;
      line-height: 15px;
    }
  }
}