.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.4;
  z-index: 1024;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1025;
  text-align: center;
}

.modal-header {
  position: relative;
}

.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.modal-title {
  padding: 15px;
  color: var(--fn1);
  font-size: var(--size-16px);
  // border-bottom: 1px solid #e8e8e8;
}

.modal-big-title {
  font-size: var(--size-16px);
  font-weight: 600;
}

.modal-body {
  // padding: 30px;
  font-size: var(--size-14px);

  button {
    border-radius: 25px;
  }

  // border-bottom: 1px solid #e8e8e8;
}

.modal-footer {
  text-align: center;
  // padding: 20px;
  display: flex;
  justify-content: end;
}

.modal-footer .r-btn {
  text-align: center;
  margin: 0 8px;
}

.modal-footer .modal-cancel-btn {
  /* background: white;
  margin-right: 30px;
  border-color: #d9d9d9;
  border-radius: 4px; */
}

.modal-footer .modal-confirm-btn {
  /* background: #1890ff; */
  /* color: white;  */
}

.modal-inviation-title {
  display: flex;
  color: var(--fn1);
  font-size: var(--size-16px);
  font-weight: 500;
  & :nth-child(2) {
    margin-left: 389px;
  }
}

.team-inviation-content {
  width: 881px;
  height: 464px;
  margin: 32px 0px;
  display: flex;
  justify-content: space-between;

  .team-inviation-add {
    width: 451px;
    height: 464px;
  }

  .team-inviation-project-list {
    border: 1px solid var(--bg1);
    box-sizing: border-box;
    border-radius: 5px;
    width: 414px;
    height: 464px;

    .team-invitation-project-list-content {
      overflow-x: hidden;
      overflow-y: auto;
      height: 420px;
      color: var(--fn3);

      .team-invitation-project-list-content-item {
        display: flex;
        align-items: center;
        height: 60px;
        cursor: pointer;
        margin: 0 8px;
        padding: 0 22px 0 22px;
        &:hover {
          background: var(--bgr1);
          border-radius: 5px;
        }
        div,
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          img{
            border: solid 1px var(--bg1);
            border-radius: 50%;
                    
          }
        }

        &>div:nth-child(2) {
          flex: 1;
          margin-left: 10px;
          text-align: left;

          &>div:nth-child(2) {
            color: var(--fn3);
            font-size: var(--szie-14px);
            margin: 6px 0 0 0;
            height: 15px;
          }
        }

        &>span:nth-child(2) {
          margin-left: 8px;
          width: 315px;
          text-align: left;
          white-space: nowrap;
        }

        &>span:nth-child(3) {
          color: var(--fn3);
          font-size: var(--szie-14px);
        }

        &>span:nth-child(4) {
          margin-left: 16px;
        }
        &:hover{
          transition: .3s linear;
        }
      }
      .project-list-item-checked {
        background: var(--bgr1);
        border-radius: 5px;
        &:hover {
          background: var(--bgr1);
        }
      }
    }
  }

  .team-inviation-add-operation {
    display: flex;
    position: relative;
    .api-input-group {
      background: var(--bg10);
      .api-input {
        background: var(--bg10);
      }
    }
    .api-select {
      background: var(--bg7);
    }
  }

  .team-invitation-add-list {
    height: 404px;
    width: 100%;
    // padding: 8px 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 15px;

    .team-invitation-add-list-item {
      margin: 0 0 16px 0;
      display: flex;
      align-items: center;

      &>span:nth-child(1) {
        position: relative;
        width: 256px;
        text-align: left;
      }

      .free-span {
        color: #3CC071;
        font-size: var(--szie-14px);
      }

      .need-buy-span {
        color: var(--error);
        font-size: var(--szie-14px);
      }
      .surplus-span {
        color: var(--fn3);
      }
      .api-input-group {
        background: var(--bg10);
        .api-input {
          background: var(--bg10);
        }
      }
      .api-select {
        background-color: var(--bg7);
        .api-select-selector-value {
          color: var(--fn3);
        }
      }
    }
  }

  .team-inviation-project-list-header {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    color: var(--fn3);
    font-weight: 600;
    font-size: var(--szie-14px);
    padding: 0 30px;

    & :nth-child(2) {
      cursor: pointer;
    }
  }
}

.team-inviation-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -31px 0;
  padding: 0 31px;
  .team-inviation-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #287EFF;
    > svg {
      margin: 0 12px 0 40px;
    }
  }

  .team-inviation-footer-l,
  .team-inviation-footer-r {
    display: flex;
    align-items: center;
  }

  .team-inviation-footer-l {
    color: var(--fn3);
    &> :nth-child(3)>img {
      margin-left: 30px;
      margin-top: -5px;
    }

    &>div:nth-child(2) {
      margin-left: 10px;
    }
  }

  .team-inviation-footer-r {
    &> :nth-child(2) {
      margin-left: 16px;
    }

    .team-inviation-footer-need-buy-span {
      color: var(--error);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.pay_add_success_messge {
  text-align: left;
  padding: 15px;
  font-size: 14px;
  color: var(--fn3);
}

.member_modal-Error {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ff4c4c;
  margin: 0 0 32px 0;
  svg {
    fill: #ff4c4c;
  }
}