.api-checkbox {
  display: flex;
  align-items: center;
  line-height: unset;
  cursor: pointer;

  .api-check-wrapper {
    margin-right: 5px;
    .api-check-box-disabled{
      background: var(--bg1);
    }
  }

  .api-check-box {
    margin: 0;
    padding: 0;
    font-size: var(--size-14px);
    list-style: none;
    position: relative;
    line-height: 1;
    // cursor: pointer;
    height: 16px;
    width: 16px;
    border: 1px solid #979797;
    border-radius: 3px;
    display: block;

    &:hover {
      transition: .3s linear;
      // border: 1px solid var(--background-13-1);
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      &:disabled{
        cursor: not-allowed;
      }
    }
  }

  .api-check-box-active {
    color: black;
    background-color: #FA7600;
    border: 0 !important;

    &::after {
      position: absolute;
      display: table;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1);
      opacity: 1;
      transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
      content: " ";
      width: 30%;
      height: 50%;
      left: 27%;
      top: 7%;
    }
  }
}