.api-btn {
  border-radius: 5px;
  font-size: var(--size-16px);
  color: var(--fn3);
  border: 1px solid var(--bd4);
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: 0.3s linear;
    background-color: var(--bg4);
  }

  &:active {
    transition: 0.3s linear;
    background-color: #eee;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:disabled {
    // cursor: pointer;
  }
}

.api-btn-small {
  font-size: var(--size-14px);
}

.api-btn-large {
  font-size: var(--size-24px);
}

.api-btn-primary {
  border: 0;
  color: var(--fn1);
  background-color: var(--bg6);

  &:hover {
    transition: 0.3s linear;
    background-color: var(--bg6);
    opacity: 0.8;
  }
}

.api-btn-link {
  border: 0;
  color: var(--main-1);
  font-weight: normal;
  background: transparent;
  padding: 0;

  &:hover {
    transition: 0.3s linear;
    opacity: 0.5;
    background-color: transparent;
  }
}
