.api-post-table {
  table-layout: fixed;
  display: table;
  border-collapse: collapse;
  width: 100%;

  tr {
    display: table-row;
  }

  .api-post-table-td-border {
    border: 1px solid var(--bd4);
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    position: relative;
    word-break: break-all;
  }
  
  .td-scale{
    position: absolute;
    top: 0;
    right: -2px;
    z-index: 99;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    &.scaling{
      background-color: #3a86ff;
    }
  }

}

.api-post-cell {
  // padding: 20px;
  height: 52px;
  vertical-align: middle;
  position: relative;
  color: var(--fn3);
  font-weight: 400;
  font-size: var(--size-12px);
  text-align: left;
  // background: #fafafa;
  // border: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}