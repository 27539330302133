.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.4;
  z-index: 1024;
}

.modal-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--bd4);
  border-radius: 5px;
  z-index: 1025;
  text-align: center;
  max-width: 1200px;
  max-height: 880px;
  width: 80%;
  // height: 75%;
  overflow: hidden;
  // min-width: 520px;
  // min-height: 480px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.modal-header {
  color: var(--fn1);
  background-color: var(--bg7);
  position: relative;
  max-height: 50px;
}

.modal-close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1026;
  border-radius: 5px;
  background-color: var(--bg2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--fn2);
  }
  &:hover {
    background-color: var(--bg1);
    svg {
      fill: var(--fn1);
    }
  }
}

.modal-title {
  padding: 15px;
  // color: black;
  font-size: var(--size-16px);
  text-align: left;

  // border-bottom: 1px solid #e8e8e8;
}

.modal-body {
  padding: 30px;
 
  font-size: var(--size-14px);
  background: var(--bg7);
  // height: ( 100% - 100px );
  // max-height: 60%;
  button {
    border-radius: 25px;
  }
  // border-bottom: 1px solid #e8e8e8;
}

.modal-body::-webkit-scrollbar {
  width: 0 !important;
}
.modal-footer {
  text-align: center;
  // padding: 20px;
  display: flex;
  justify-content: end;
  margin-top: 16px;
  margin-left: -31px;
  margin-right: -31px;
}

.modal-footer .r-btn {
  text-align: center;
  margin: 0 8px;
}

.apipost-confirm {
  &-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    background-color: var(--bgr4);
  }
  &-container {
    position: absolute;
    width: 380px;
    min-height: 208px;
    padding: 30px;
    left: 50%;
    top: 50%;
    border-radius: 5px;
    background-color: var(--bg7);
    border: 1px solid var(--bd6);
    transform: translate(-50%, -50%);
    z-index: 1101;
    display: flex;
    flex-direction: column;

    .svg-close {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      background-color: var(--bg2);
      position: absolute;
      right: 30px;
      top: 30px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      fill: var(--fn1);
      &:hover {
        background-color: var(--bgr1);
      }
    }

    &-title {
      height: 20px;
      font-size: var(--size-16px);
      color: var(--fn1);
    }
    &-content {
      padding: 30px 0;
      line-height: 24px;
      flex: 1;
      color: var(--fn3);
    }

    &-footer {
      height: 30px;
      display: flex;
      justify-content: end;
      align-items: center;
      &-btn {
        width: 120px;
        height: 40px;
        margin-left: 16px;
        border-radius: 5px;
        color: #ffffff;
        border: none;
        font-size: var(--size-12px);
        background-color: var(--bg6);
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        &.btnclose {
          color: var(--fn3);
          background-color: var(--bg4);
        }
        &.redbtn {
          background-color: var(--error);
        }
      }
    }

    &.large {
      width: 520px;

      & .apipost-confirm-container-footer {
        justify-content: flex-end;
        & > div {
          width: auto;
          min-width: 102px;
        }
        & > div:first-child {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}

.apipost-modalshow {
  & .modal-show-container {
    position: fixed;
    z-index: 1025;
  }
}

@media screen and (max-width: 1000px) {
  .modal-container {
    width: 80%;
  }
}
@media screen and (max-height: 880px) {
  .modal-container {
    height: 80%;
  }
}
