.apt_alert{
  top: 0;
  position: fixed;
  z-index: 9999;
  font-size: 14px;
  .apt_alert-close_icon{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &>div{
    padding: 12px 14px 12px 12px;
  }
  .apt_alert-warn{
    // background-color: #DFBB00;
  }
}