.api-topTool {
  width: 100%;
  height: 6px;
  position: fixed;
  // z-index: 1;
  .topbar {
    background-color: #5d88f1;
    height: 100%;
    cursor: pointer;
  }
}
.topbarCon {
  position: relative;
  .api-drawer-content {
    border-radius: 0;
    background-color: #e1eaff;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 40px;
    justify-content: center;
    align-items: center;
    border: none;
    .api-drawer-close {
      position: absolute;
      right: 30px;
      top: 12px;
    }
  }
}
// .example-enter {
//   opacity: 0.01;
// }

// .example-enter.example-enter-active {
//   opacity: 1;
//   transition: opacity 500ms ease-in;
// }

// .example-leave {
//   opacity: 1;
// }

// .example-leave.example-leave-active {
//   opacity: 0.01;
//   transition: opacity 300ms ease-in;
// }
// .apt_alert.alert_toolbar {
//   width: 100%;
//   left: 50%;
//   top: 0px !important;
//   transform: translateX(-50%);
//   background: #fffee8;
//   /* 通知/警告/边框 */
//   border: 1px solid #dfbb00;
//   box-sizing: border-box;
//   border-radius: 5px;
//   color: #2b2b2b;
//   position: static;
//   transform: none;
//   & > div {
//     display: flex;
//     align-items: center;
//     position: relative;
//   }
//   .apt_alert-icon {
//     margin-right: 12px;
//   }
// }
