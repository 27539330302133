.api-post-input-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column_before,
.column_after {
  position: absolute;
  right: 0;
  fill: var(--fn1);
}
.column_before {
  top: 6px;
  right: 10px;
  border-radius: 0 0 3px 3px;
}
.column_after {
  bottom: 6px;
  right: 10px;
  border-radius: 3px 3px 0 0;
}
