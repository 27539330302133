.api_Notice_modal {
  // border: 1px solid var(--bd4);
  border-radius:5px;
  width: max-content !important;
  height: max-content !important;
  .notice_header {
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    color: var(--fn1);
    border-bottom: 1px solid var(--bd1);
    svg{
      margin-right: 4px;
    }
  }
}
.api_Notice_modal_foot {
  height: 60px;
  width: 100%;
  background: var(--bg1);
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid var(--bd4);
  display: flex;
  padding: 0 24px;
  justify-content: flex-end;
  align-items: center;
  .api_Notice_modal_btn {
    width: 153px;
    height: 41px;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin: 0 12px;
  }
  .api_Notice_modal_btn_default{
    background-color: var(--bg4);
    color: var(--fn1);
  }
}
