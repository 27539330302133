#loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo-container{
    width: 100px;
    display: flex;
    flex-direction: column;
    #loading-content{
      width: 100px;
    }
    #loading-logo{
      svg{
        fill: #3A86FF;
      }
    }
  }


  &.dark{
    background-color: #000000;
    svg{
      fill: #666666 !important;
    }
  }
  

}
