.api-drawer {
  top: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  // transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  // box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  .api-drawer-content {
    position: absolute;
    padding: 16px;
    background-color: var(--mbg3);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: solid 2px var(--bd8);
    border-right: none;
    .api-drawer-close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &>svg{
        fill: var(--fn1);
      }
    }
  }
  &.api-drawer-left {
    left: 0;
    transform: translate(-100%, 0);
    // .api-drawer-content {
    //   height: 100%;
    // }
  }
  &.api-drawer-right {
    right: 0;
    transform: translate(100%, 0);
    // .api-drawer-content {
    //   height: 100%;
    // }
  }
  &.api-drawer-top {
    top: 0;
    transform: translate(0, -100%);
  }
  &.api-drawer-bottom {
    bottom: 0;
    transform: translate(0, 100%);
    // .api-drawer-content {
    //   width: 100%;
    // }
  }
}
.api-drawer-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}
